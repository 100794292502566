import { makeStyles } from "tss-react/mui";

export default makeStyles()(({palette}) => ({
  root: {
    backgroundColor: "#100030",
  },
  navLink: {
    textDecoration: "none",
    color: palette.text.primary,
  },
  icons: {
    width: 40,
    height: 40,
  },
}))