import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import linkedIn from "../../assets/images/linkedIn.svg";
import logo from "../../assets/images/logo-white.png";
import twitter from "../../assets/images/twitter.svg";
import whatsApp from "../../assets/images/whatsapp.svg";
import InnerContainer from "../InnerContainer/InnerContainer";
import footerStyle from "./footerStyle";
import ScrollUp from "../ScrollUp/ScrollUp";

function Footer() {
  const { classes } = footerStyle();
  return (
    <Box className={classes.root} component={"footer"}>
      <InnerContainer spacing={3}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={3}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            component={"figure"}
            sx={{ m: 0 }}
          >
            <Link to={{ hash: "" }} className={classes.navLink}>
              <Box
                component={"img"}
                src={logo}
                alt="ETeam Technology Logo"
                sx={{ height: "40px" }}
              />
            </Link>
            <Link to={{ hash: "" }} className={classes.navLink}>
              <Typography
                variant="body2"
                sx={{ display: { xs: "none", md: "block" } }}
                color={"secondary"}
                component={"figcaption"}
              >
                ETEAM
                <br />
                TECHNOLOGY
              </Typography>
            </Link>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1.5}
            component={"address"}
          >
            <Tooltip title={"LinkedIn"} placement={"top"} arrow>
              <Avatar
                component={"a"}
                href={"https://www.linkedin.com/in/eteam-technology-42b178241"}
                target={"_blank"}
                src={linkedIn}
                className={classes.icons}
              />
            </Tooltip>
            <Tooltip title={"Twitter"} placement={"top"} arrow>
              <Avatar
                component={"a"}
                href={
                  "https://twitter.com/EteamTechnology?t=IuX9u9libcrcVsILJsDR7w&s=09"
                }
                target={"_blank"}
                src={twitter}
                className={classes.icons}
              />
            </Tooltip>
            <Tooltip title={"Instagram"} placement={"top"} arrow>
              <Avatar
                component={"a"}
                href={"https://www.instagram.com/eteam_tech/"}
                target={"_blank"}
                src={instagram}
                className={classes.icons}
              />
            </Tooltip>
            <Tooltip title={"Facebook"} placement={"top"} arrow>
              <Avatar
                component={"a"}
                href={"https://www.facebook.com/profile.php?id=100083407306239"}
                target={"_blank"}
                src={facebook}
                className={classes.icons}
              />
            </Tooltip>
            <Tooltip title={"WhatsApp"} placement={"top"} arrow>
              <Avatar
                component={"a"}
                href={"https://wa.me/2349026025595"}
                target={"_blank"}
                src={whatsApp}
                className={classes.icons}
              />
            </Tooltip>
          </Stack>
        </Stack>
      </InnerContainer>
      <ScrollUp />
      <Divider color="rgba(255, 255, 255, 0.11)" />
      <InnerContainer spacing={3}>
        <Typography variant="caption" color={"secondary"}>
          Copyright © {new Date().getFullYear()} ETeam Technology. All right
          reserved
        </Typography>
      </InnerContainer>
    </Box>
  );
}

export default Footer;
