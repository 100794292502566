import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";
import img from "../../assets/images/about.svg";
import InnerContainer from "../InnerContainer/InnerContainer";

function About(_: any, ref: any) {
  return (
    <InnerContainer ref={ref} spacing={15}>
      <Grid container spacing={3} alignItems={"center"} component={"section"}>
        <Grid item sm={12} md={6} lg={7} component={"figure"}>
          <Box
            sx={{ width: "100%" }}
            component={"img"}
            src={img}
            alt="group-of-people"
          />
          <Box hidden component={"figcaption"}>
            About ETeam Technology
          </Box>
        </Grid>
        <Grid item sm={12} md={6} lg={5} component={"article"}>
          <Typography variant="h3" gutterBottom>
            ABOUT US
          </Typography>
          <Typography variant="body1" align="justify">
            ETeam Technology is an ICT Company with the vision to operate on a
            platform of excellence in all our dealings.
          </Typography>
          <br />
          <Typography variant="body1" align="justify">
            Our goal is to create, manage, execute, and deliver excellent
            products and services in the ICT field, because{" "}
            <em>"excellence is our benchmark"</em>.
          </Typography>
          <br />
          <Typography variant="body1" align="justify">
            We are keen on spreading ICT knowledge and identifying the need for
            ICT in all sectors without age limits via trainings, seminars,
            conferences, and many more.
          </Typography>
          <br />
          <Typography variant="body1" align="justify">
            Our mission includes impacting the lives of youth worldwide through
            the knowledge of ICT by becoming an ambassador for excellence.
          </Typography>
        </Grid>
      </Grid>
    </InnerContainer>
  );
}

export default forwardRef(About);
