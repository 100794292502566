import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { forwardRef, ReactNode } from "react";

type Props = {
  children: ReactNode;
  spacing?: number;
  spacingTop?: number;
  spacingBottom?: number;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
};

function InnerContainer(
  {
    children,
    spacing,
    spacingTop,
    spacingBottom,
    noMarginLeft,
    noMarginRight,
  }: Props,
  ref: any
) {
  const theme = useTheme();

  return (
    <Container
      ref={ref}
      sx={{
        paddingTop: theme.spacing(spacing || spacingTop || 0),
        paddingBottom: theme.spacing(spacing || spacingBottom || 0),
        marginLeft: noMarginLeft ? 0 : "auto",
        marginRight: noMarginRight ? 0 : "auto",
      }}
      fixed
    >
      {children}
    </Container>
  );
}

export default forwardRef(InnerContainer);
