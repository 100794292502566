import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail, MdPhone } from "react-icons/md";
import InnerContainer from "../InnerContainer/InnerContainer";

function Contact(_: any, ref: any) {
  return (
    <InnerContainer ref={ref} spacing={15}>
      <section>
        <Typography variant="h3" gutterBottom>
          CONTACT US
        </Typography>
        <br />
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={4}>
            <Stack direction={"column"} gap={3} component={"article"}>
              <Stack
                direction={"row"}
                gap={3}
                alignItems={"center"}
                component={"address"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 70,
                    height: 70,
                  }}
                  variant="rounded"
                  component={"a"}
                  href={"https://goo.gl/maps/htofH12FUmoWjYzHA"}
                  target={"_blank"}
                >
                  <HiLocationMarker size={40} color="white" />
                </Avatar>
                <Box>
                  <Typography variant={"h6"} fontStyle={"normal"}>
                    Office Address:
                  </Typography>
                  <Typography variant="body1" fontStyle={"normal"}>
                    44 Alh. Bakare St, 332432, Ojodu, Berger, Lagos
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                gap={3}
                alignItems={"center"}
                component={"address"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 70,
                    height: 70,
                  }}
                  variant="rounded"
                  component={"a"}
                  href={"mailto:info@eteam.ng"}
                >
                  <MdEmail size={40} color="white" />
                </Avatar>
                <Box>
                  <Typography variant={"h6"} fontStyle={"normal"}>
                    Email Address:
                  </Typography>
                  <Typography variant="body1" fontStyle={"normal"}>
                    info@eteam.ng
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                gap={3}
                alignItems={"center"}
                component={"address"}
              >
                <Avatar
                  sx={{
                    backgroundColor: "primary.main",
                    width: 70,
                    height: 70,
                  }}
                  variant="rounded"
                  component={"a"}
                  href={"tel:+2349026025595"}
                >
                  <MdPhone size={40} color="white" />
                </Avatar>
                <Box>
                  <Typography variant={"h6"} fontStyle={"normal"}>
                    Phone Number:
                  </Typography>
                  <Typography variant="body1" fontStyle={"normal"}>
                    +234 902 602 5595
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={8} component={"figure"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31705.756836827037!2d3.3371149682711954!3d6.61961869363428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93d43ed1bd39%3A0xd9a262580775dba5!2sETeam%20Technology!5e0!3m2!1sen!2sng!4v1672974090907!5m2!1sen!2sng"
              style={{
                border: 0,
                width: "100%",
                height: "auto",
                aspectRatio: "1",
              }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="location"
            />
            <Box hidden component={"figcaption"}>
              ETeam Technology Location on Map
            </Box>
          </Grid>
        </Grid>
      </section>
    </InnerContainer>
  );
}

export default forwardRef(Contact);
