import { makeStyles } from "tss-react/mui";
import serviceBg from "../../assets/images/service-bg.svg";

export default makeStyles()((theme) => ({
  circle: {
    backgroundColor: "#F2ECFF",
    width: "100px",
    height: "100px",
    borderRadius: "100px",
  },
  chip: {
    marginBottom: `${theme.spacing(1)} !important`,
  },
  parallax: {
    backgroundColor: theme.palette.primary.dark,
    // marginBottom: theme.spacing(15),
    backgroundImage: `url(${serviceBg})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center bottom",
  },
}));
