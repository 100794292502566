import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyARSw_rrhThTQj9juXsTz8WtTh5jPxib0k",
  authDomain: "eteam-web.firebaseapp.com",
  projectId: "eteam-web",
  storageBucket: "eteam-web.appspot.com",
  messagingSenderId: "444802888836",
  appId: "1:444802888836:web:8d663f3675ed3bbaacbed1",
  measurementId: "G-G5HSZDDP3C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const screenView = (screen: string) => {
  logEvent(analytics, "screen_view", {
    firebase_screen: screen,
    firebase_screen_class: "Page Section",
    screen_name: screen,
  });
};

const bookAppointment = () => {
  logEvent(analytics, "generate_lead", {
    currency: "NGN",
    value: 0,
  });
};

export { screenView, bookAppointment };
