import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";
import img1 from "../../assets/images/service-icon1.svg";
import img2 from "../../assets/images/service-icon2.svg";
import img3 from "../../assets/images/service-icon3.svg";
import InnerContainer from "../InnerContainer/InnerContainer";
import serviceStyle from "./serviceStyle";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CardActions from "@mui/material/CardActions";

function Services(_: any, ref: any) {
  const { classes } = serviceStyle();
  const list = [
    {
      icon: img1,
      title: "Software Engineering (EaaS)",
      text: "In today's disruptive landscape, your software must be built to adapt, just like your business is. We have teams of specialized software engineers, architects and developers who creatively solve business problems.",
      tags: [
        "Future Proof",
        "Agile|Agile Software Development",
        "Development",
        "Testing",
        "Deployment",
        "Maintenance",
        "FullStack",
        "DevOps|Software Development and Operations",
        "Scaling",
      ],
    },
    {
      icon: img2,
      title: "Enterprise Solutions (ESS)",
      text: "We help organizations automate business workflows through business process software as a service (SaaS) solutions. Using the latest technological innovations to provide you leverage and compliance in today's ever-changing business landscape.",
      tags: [
        "SaaS|Software as a Service",
        "SFA|Sales Force Automation",
        "ERP|Enterprise Resource Planning",
        "CRM|Customer Relationship Management",
        "AI/ML|Artificial Intelligence and Machine Learning",
        "MVP|Minimum Viable Product",
        "FINTECH|Financial Technology",
      ],
    },
    {
      icon: img3,
      title: "IT Consultancy",
      text: "We evaluate the company's IT systems to help them meet their business objectives. We also provide advisory services that help our clients assess different technological strategies to align their technology with their business.",
      tags: [
        "Digital Transformation",
        "IT Strategy",
        "IT Assessment",
        "IT Operations",
        "Project Management",
        "Support",
        "Infrastructure Management",
      ],
    },
  ];

  return (
    <section>
      <InnerContainer ref={ref} spacingTop={15}>
        <article>
          <Typography variant="h3" align="center" gutterBottom>
            SERVICES WE PROVIDE
          </Typography>
          <Typography align="center" variant="body1">
            At ETeam Technology, we provide commercial and enterprise software
            engineering services to our clients and partners.
          </Typography>
          <Typography align="center" variant="body1">
            We also offer consultancy services for already established
            organizations and startups.
          </Typography>
        </article>
        <br />
        <br />
      </InnerContainer>

      <Box className={classes.parallax}>
        <InnerContainer spacing={10}>
          <Grid container spacing={3}>
            {list.map((item, key) => (
              <Grid key={key} item sm={12} md={4}>
                <Card
                  sx={{ width: "100%", height: "100%", display: "grid" }}
                  component={"article"}
                >
                  <CardContent>
                    <br />
                    <Stack
                      direction={"column"}
                      alignContent={"space-between"}
                      alignItems={"center"}
                      spacing={3}
                    >
                      <Stack
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        className={classes.circle}
                      >
                        <Box component={"figure"} sx={{ m: 0 }}>
                          <Box
                            component={"img"}
                            src={item.icon}
                            alt="service-img"
                            sx={{ width: "40px" }}
                          />
                          <Box hidden component={"figcaption"}>
                            {item.title}
                          </Box>
                        </Box>
                      </Stack>
                      <Box component={"article"}>
                        <Typography variant="h6" align="center" gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography align="center" variant="body1" gutterBottom>
                          {item.text}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        flexWrap={"wrap"}
                        justifyContent={"center"}
                      >
                        {item.tags.map((tagItem, id) => {
                          const [tag, text] = tagItem.split("|");
                          return (
                            <Tooltip
                              key={id}
                              title={text || tag}
                              arrow
                              placement={"top"}
                            >
                              <Chip
                                label={
                                  text ? <abbr title={text}>{tag}</abbr> : tag
                                }
                                color={"primary"}
                                variant={"outlined"}
                                size={"small"}
                                className={classes.chip}
                                clickable
                              />
                            </Tooltip>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <AppointmentModal
                      color="primary"
                      variant={"text"}
                      endIcon={<ArrowForward />}
                      fullWidth
                    >
                      Learn more
                    </AppointmentModal>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </InnerContainer>
      </Box>
    </section>
  );
}

export default forwardRef(Services);
