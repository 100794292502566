import { makeStyles } from "tss-react/mui";

export default makeStyles()(() => ({
  root: { backgroundColor: "#F7F7F7" },
  card: {
    width: "100%",
    height: "100%",
    display: "grid",
  },
}));
