import { makeStyles } from "tss-react/mui";

export default makeStyles<{ overlay: boolean }>()(
  ({ spacing, palette }, { overlay }) => ({
    root: {
      background: overlay ? "rgba(255, 255, 255, 0.6)" : "transparent",
      backdropFilter: overlay ? "blur(10px)" : "none",
      webkitBackdropFilter: overlay ? "blur(10px)" : "none",
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      borderBottom: overlay ? "1px solid white" : "none",
      boxShadow: overlay ? "0 1px 5px rgba(0,0,0,0.05)" : "none",
    },
    navCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    navLink: {
      textDecoration: "none",
      color: palette.text.primary,
    },
  })
);
