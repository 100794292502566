// Create a theme instance.
import createTheme from "@mui/material/styles/createTheme";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5E17EB",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#FCFCFC",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontWeightBold: 700,
    fontWeightLight: 100,
    fontWeightMedium: 400,
    fontWeightRegular: 300,
    fontSize: 16,
    body1: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      fontSize: 18,
    },
    button: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      textTransform: "none",
    },
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 900,
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 700,
      fontSize: 32,
    },
    h4: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
    },
  },
});

export default responsiveFontSizes(theme);
