import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";

export default function ScrollUp() {
  const toTop = useScrollTrigger({ disableHysteresis: true, threshold: 500 });
  const scrollToTop = () => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <Zoom in={toTop} timeout={500}>
      <Tooltip title={"Scroll to Top"} placement={"top"} arrow>
        <Fab
          color="primary"
          size="large"
          sx={{ position: "fixed", right: "60px", bottom: "60px" }}
          onClick={scrollToTop}
        >
          <KeyboardArrowUp fontSize={"large"} />
        </Fab>
      </Tooltip>
    </Zoom>
  );
}
