import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { MenuLinks } from "../../helpers/links";
import InnerContainer from "../InnerContainer/InnerContainer";
import NavStyle from "./NavStyle";
import Close from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import SupportAgent from "@mui/icons-material/SupportAgent";

function Navbar() {
  const overlay = useScrollTrigger({ disableHysteresis: true });
  const { classes } = NavStyle({ overlay });
  const { hash } = useLocation();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position={"sticky"}
        color={overlay ? "secondary" : "transparent"}
        className={classes.root}
      >
        <InnerContainer>
          <Toolbar disableGutters className={classes.navCenter}>
            <IconButton
              sx={{ display: { xs: "inherit", md: "none" } }}
              size="large"
              edge="start"
              color="primary"
              onClick={onOpen}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              component={"figure"}
              sx={{ m: 0 }}
            >
              <Link className={classes.navLink} to={{ hash: "" }}>
                <Box
                  component={"img"}
                  src={logo}
                  alt="ETeam Technology Logo"
                  sx={{ height: "40px" }}
                />
              </Link>
              <Box
                component={Link}
                sx={{ display: { xs: "none", md: "block" } }}
                className={classes.navLink}
                to={{ hash: "" }}
              >
                <Typography variant="body2" component={"figcaption"}>
                  ETEAM
                  <br />
                  TECHNOLOGY
                </Typography>
              </Box>
            </Stack>
            <Stack
              sx={{ display: { xs: "none", md: "block" } }}
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              component={"nav"}
            >
              {MenuLinks.map(({ text, url }, key) => (
                <Button
                  key={key}
                  variant="text"
                  color={hash === url ? "primary" : "inherit"}
                  component={Link}
                  to={{ hash: url }}
                  size="large"
                  sx={{ fontWeight: hash === url ? 500 : 400 }}
                >
                  {text}
                </Button>
              ))}
            </Stack>
            <Box
              sx={{ display: { xs: "none", md: "block" }, fontStyle: "normal" }}
              component={"address"}
            >
              <Tooltip title={"24/7 Support"} placement={"bottom"} arrow>
                <AppointmentModal
                  variant="contained"
                  startIcon={<SupportAgent />}
                >
                  Support
                </AppointmentModal>
              </Tooltip>
            </Box>
            <AppointmentModal
              sx={{ display: { xs: "inherit", md: "none" } }}
              size="large"
              color="primary"
              iconButton
            >
              <Tooltip title={"24/7 Support"} placement={"bottom-start"} arrow>
                <SupportAgent />
              </Tooltip>
            </AppointmentModal>
          </Toolbar>
        </InnerContainer>
      </AppBar>
      <SwipeableDrawer
        anchor="top"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      >
        <InnerContainer>
          <Toolbar disableGutters className={classes.navCenter}>
            <IconButton
              size="large"
              edge="start"
              color="primary"
              onClick={onClose}
            >
              <Close />
            </IconButton>
            <Link to={{ hash: "" }} onClick={onClose}>
              <Box component={"figure"} sx={{ m: 0 }}>
                <Box
                  component={"img"}
                  src={logo}
                  alt="ETeam Technology Logo"
                  sx={{ height: "40px" }}
                />
                <Box hidden component={"figcaption"}>
                  ETeam Technology Logo
                </Box>
              </Box>
            </Link>
            <AppointmentModal size="large" color="primary" iconButton>
              <SupportAgent />
            </AppointmentModal>
          </Toolbar>
        </InnerContainer>
        <Divider />
        <InnerContainer spacing={2}>
          <nav>
            <List>
              {MenuLinks.map(({ text, url }, key) => (
                <Fragment key={key}>
                  <ListItem key={key} disablePadding>
                    <ListItemButton
                      selected={hash === url}
                      onClick={onClose}
                      component={Link}
                      to={{ hash: url }}
                    >
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                  {key < MenuLinks.length - 1 ? (
                    <Divider component="li" />
                  ) : null}
                </Fragment>
              ))}
            </List>
          </nav>
        </InnerContainer>
      </SwipeableDrawer>
    </>
  );
}

export default Navbar;
