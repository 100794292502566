import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { forwardRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { bookAppointment } from "../../helpers/analytics";
import EventAvailable from "@mui/icons-material/EventAvailable";
import Stack from "@mui/material/Stack";

type Props = ButtonProps & { iconButton?: boolean };

function AppointmentModal({ children, iconButton, ...props }: Props, ref: any) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    bookAppointment();
  };

  return (
    <>
      {iconButton ? (
        <IconButton ref={ref} {...props} onClick={handleClickOpen}>
          {children}
        </IconButton>
      ) : (
        <Button ref={ref} {...props} onClick={handleClickOpen}>
          {children}
        </Button>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        scroll={"paper"}
        keepMounted
      >
        <DialogTitle color={"primary"}>
          <Stack direction={"row"} alignItems={"center"}>
            <EventAvailable sx={{ mr: 1 }} />
            Make an Appointment
          </Stack>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ aspectRatio: "1", height: "auto" }}>
          {/* <!-- Google Calendar Appointment Scheduling begin --> */}
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1Ky4_H43I-Sa20eeFLNF16ji7dXjOez5cDvTGyrZaJdKi-NYa-e1102q8A0sl_uehrhm9U6c6e?gv=true"
            style={{ border: 0, width: "100%", height: "100%" }}
            title={"Make an Appointment"}
          />
          {/* <!--end Google Calendar Appointment Scheduling --> */}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default forwardRef(AppointmentModal);
