export const MenuLinks = [
  {
    url: "#about",
    text: "About",
  },
  {
    url: "#services",
    text: "Services",
  },
  {
    url: "#solutions",
    text: "Solutions",
  },
  // {
  //   url: "#testimonials",
  //   text: "Testimonials",
  // },
  {
    url: "#contact",
    text: "Contact us",
  },
];
