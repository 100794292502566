import { createRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Project from "./components/Projects/Project";
import Services from "./components/Services/Services";
import { screenView } from "./helpers/analytics";

function App() {
  const { hash } = useLocation();
  const aboutRef = createRef<HTMLElement>();
  const servicesRef = createRef<HTMLElement>();
  const projectRef = createRef<HTMLElement>();
  // const testimonialRef = createRef<HTMLElement>();
  const contactRef = createRef<HTMLElement>();

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ behavior: "smooth", top: 0 });
    } else if (hash === "#about") {
      screenView(hash);
      aboutRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#services") {
      screenView(hash);
      servicesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#solutions") {
      screenView(hash);
      projectRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#testimonials") {
      screenView(hash);
      // testimonialRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#contact") {
      screenView(hash);
      contactRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <>
      <Navbar />
      <main>
        <Header />
        <About ref={aboutRef} />
        <Services ref={servicesRef} />
        <Project ref={projectRef} />
        {/* <Testimonial ref={testimonialRef} /> */}
        <Contact ref={contactRef} />
      </main>
      <Footer />
    </>
  );
}

export default App;
