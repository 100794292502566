import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";
import image8 from "../../assets/images/api.jpg";
import image5 from "../../assets/images/crm.jpg";
import image6 from "../../assets/images/erp.jpg";
import image3 from "../../assets/images/fintech.jpg";
import image1 from "../../assets/images/mobile-app.jpg";
import image7 from "../../assets/images/mvp.jpg";
import image12 from "../../assets/images/open-source.jpg";
import image2 from "../../assets/images/pos.jpg";
import image10 from "../../assets/images/team.jpg";
import image4 from "../../assets/images/vr.jpg";
import image11 from "../../assets/images/whatsapp.jpg";
import image9 from "../../assets/images/widget.jpg";
import InnerContainer from "../InnerContainer/InnerContainer";
import projectStyle from "./projectStyle";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import ContactSupport from "@mui/icons-material/ContactSupport";

function Project(_: any, ref: any) {
  const { classes } = projectStyle();
  const solutions = [
    {
      title: "CRM Solutions",
      text: "Customer Relationships Management solution tailored to your specific business needs.",
      image: image5,
    },
    {
      title: "ERP Solutions",
      text: "Enterprise Resource Planning solution specially designed to scale alongside your business.",
      image: image6,
    },
    {
      title: "POS Solutions",
      text: "Point of sale solutions for SMEs and Enterprises",
      image: image2,
    },
    {
      title: "VR Technology",
      text: "We help migrate or extend websites, products and services to 3D Virtual environments (a.k.a Meta Verse).",
      image: image4,
    },
    {
      title: "Mobile Applications (Android, iOS, Huawei)",
      text: "Tech product consumption around the globe has shifted towards mobile platforms, and we build with the latest mobile frameworks for all platforms.",
      image: image1,
    },
    {
      title: "FinTech Solutions",
      text: "We have over 8years of experience building Financial management, Blockchain, FX and banking solutions for FinTech companies and financial houses.",
      image: image3,
    },
    {
      title: "MVP for Startups",
      text: "We help new startup companies bootstrap their first minimum viable product to interface with the market on time.",
      image: image7,
    },
    {
      title: "REST API Solutions",
      text: "We build secure Restful API solutions for Organizations who want to extend their business logic to partners, third parties or the public for consumption.",
      image: image8,
    },
    {
      title: "Custom Widget Solutions",
      text: "The IT industry has evolved how data has been shared and displayed on the web and mobile interfaces. Widgets are the easiest and most secure way to share data with control over data privacy.",
      image: image9,
    },
    {
      title: "Software Engineering (EaaS)",
      text: "We help companies set up software engineering teams and departments with optimal performance and skills that suit your business needs.",
      image: image10,
    },
    {
      title: "WhatsApp Bot's",
      text: "Extend your services directly to your customer's DM and drive more user engagement by putting your products and services at their fingertips.",
      image: image11,
    },
    {
      title: "Open Source Solutions",
      text: "At ETeam Technology, we support the open-source community by investing in building open-source solutions for public consumption, all for free.",
      image: image12,
    },
  ];

  return (
    <Box className={classes.root} component={"section"}>
      <InnerContainer ref={ref} spacing={15}>
        <article>
          <Typography variant={"h3"} gutterBottom>
            SOLUTIONS
          </Typography>
          <Typography variant={"body1"} gutterBottom>
            We offer advanced and brilliant IT solutions carefully crafted by
            experts in the tech industry with years of experience working with
            cutting-edge technologies.
          </Typography>
        </article>
        <br />
        <br />
        <Grid container spacing={3}>
          {solutions.map((item, key) => (
            <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
              <Card
                elevation={0}
                className={classes.card}
                component={"article"}
              >
                <Box component={"figure"} sx={{ m: 0 }}>
                  <CardMedia
                    component={"img"}
                    height={200}
                    image={item.image}
                    alt={item.title}
                  />
                  <Box hidden component={"figcaption"}>
                    {item.title}
                  </Box>
                </Box>
                <CardContent component={"article"}>
                  <Typography gutterBottom variant="h6">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color={"text.secondary"}>
                    {item.text}
                  </Typography>
                </CardContent>
                <CardActions>
                  <AppointmentModal
                    color="info"
                    variant={"text"}
                    endIcon={<ContactSupport />}
                    fullWidth
                  >
                    Get a Quote
                  </AppointmentModal>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </InnerContainer>
    </Box>
  );
}

export default forwardRef(Project);
