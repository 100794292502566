import EventAvailable from "@mui/icons-material/EventAvailable";
import TipsAndUpdates from "@mui/icons-material/TipsAndUpdates";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import InnerContainer from "../InnerContainer/InnerContainer";
import AppointmentModal from "../AppointmentModal/AppointmentModal";

export default function Header() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <InnerContainer spacing={15}>
      <section>
        <Typography variant="h2" align="center">
          We Build{" "}
          <Box component={"span"} sx={{ color: theme.palette.primary.main }}>
            Tech&nbsp;Projects
          </Box>{" "}
          &
          <br />
          Provide ICT Solutions
        </Typography>
        <br />
        <br />
        <Typography align="center" variant="body2">
          We build, solve & manage tech projects of all sizes.
        </Typography>
        <br />
        <br />

        <Stack
          direction={small ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <AppointmentModal
            variant="contained"
            color="primary"
            size="large"
            endIcon={<EventAvailable />}
          >
            Book Appointment
          </AppointmentModal>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            endIcon={<TipsAndUpdates />}
            component={Link}
            to={{ hash: "solutions" }}
          >
            Solutions
          </Button>
        </Stack>
      </section>
    </InnerContainer>
  );
}
